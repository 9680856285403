import { template as template_ccf53c9a259d49b697898d4ffeaa125d } from "@ember/template-compiler";
const FKLabel = template_ccf53c9a259d49b697898d4ffeaa125d(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
