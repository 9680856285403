import { template as template_5a54613764ef427c84e5621b69fe8472 } from "@ember/template-compiler";
const FKControlMenuContainer = template_5a54613764ef427c84e5621b69fe8472(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
