import { template as template_48be6f5bc324472bab890fcc24181d1c } from "@ember/template-compiler";
const SidebarSectionMessage = template_48be6f5bc324472bab890fcc24181d1c(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
