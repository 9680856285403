import { template as template_0a92af8de27e46468d79928d168ed5ac } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const ActivityCell = template_0a92af8de27e46468d79928d168ed5ac(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="activity"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="activity"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ActivityCell;
