import { template as template_5aa9229394154a13a7be642b9a852939 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const OpLikesCell = template_5aa9229394154a13a7be642b9a852939(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="op_likes"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="likes"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default OpLikesCell;
